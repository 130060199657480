var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table--no-wrap table--narrow"},[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER'))+" ")]),_c('th',{staticClass:"text-left text-wrap"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.BUNDLE_PRODUCT_CODE'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.MERCHANT_REF_NUMBER'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.CREATED'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.CREATED_BY'))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t('BACK_OFFICE_ORDERS.STATUS')))]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.ACTIONS'))+" ")])])]),(_vm.bundleOrders.length === 0)?_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"8"}},[_vm._v(_vm._s(_vm.$t('ORDERS_NO_RESULTS')))])])]):_vm._l((_vm.bundleOrders),function(order){return _c('tbody',{key:order.createdDtime},[_c('tr',{class:[
            {
              ['has-highlight']:
                +order.referenceNumber === +_vm.$route.query.referenceNumber,
              ['disabled']: _vm.refreshingOrder === order.referenceNumber,
            },
          ]},[_c('td',{staticClass:"text-center"},[(_vm.displayOrderDetails[order.referenceNumber])?_c('Button',{attrs:{"variant":"ghost","width":"auto","title":_vm.$t('CLOSE_DETAILS')},on:{"click":function($event){return _vm.closeOrderDetails(order.referenceNumber)}}},[_c('CloseDetailsSVG',{staticClass:"icon"})],1):_c('Button',{attrs:{"variant":"ghost","width":"auto","title":_vm.$t('VIEW_DETAILS')},on:{"click":function($event){return _vm.getOrderDetails(order.referenceNumber)}}},[_c('ViewDetailsSVG',{staticClass:"icon"})],1)],1),_c('td',{staticClass:"text-wrap"},[_c('strong',[_vm._v(_vm._s(order.referenceNumber))])]),_c('td',[_c('strong',[_vm._v(_vm._s(order.bundleProductCode))])]),_c('td',{staticClass:"text-wrap"},[_c('strong',[_vm._v(_vm._s(order.merchantReference))])]),_c('td',[_vm._v(" "+_vm._s(new Intl.DateTimeFormat('pl', { dateStyle: 'short', timeStyle: 'short', }).format(new Date(order.createdDtime)))+" ")]),_c('td',[_vm._v(" "+_vm._s(order.createdBy)+" ")]),_c('td',{staticClass:"text-center"},[_c('Badge',{attrs:{"variant":_vm.orderStatusVariant(order.status)}},[_vm._v(_vm._s(_vm.$t(`ORDER_STATUSES.${order.status}`)))])],1),_c('td',{staticClass:"text-right"},[(_vm.isHandedOver(order.status))?_c('Button',{attrs:{"variant":"ghost","width":"auto","title":_vm.$t('EDIT')},on:{"click":function($event){return _vm.showUpdateSerialAndMerchantReferenceModal(
                  order.referenceNumber,
                  order.serial,
                  order.merchantReference,
                )}}},[_c('EditSVG')],1):_vm._e(),(order.bankReference)?_c('Button',{attrs:{"variant":"ghost","width":"auto","disabled":!!_vm.refreshingOrder,"title":_vm.$t('REFRESH')},on:{"click":function($event){return _vm.refreshStatus(order.referenceNumber)}}},[_c('ProcessingSVG',{staticClass:"icon"})],1):_vm._e(),(_vm.canContinueThroughLink(order))?_c('Button',{attrs:{"href":order.continueLink,"target":"_blank","variant":"ghost","width":"auto","title":_vm.$t('OPEN_ORDER_LINK')}},[_c('ExternalLinkSVG',{staticClass:"icon"})],1):_vm._e(),(_vm.canHandOver(order.status))?_c('Button',{attrs:{"variant":"ghost","width":"auto","title":_vm.$t('HAND_OVER')},on:{"click":function($event){return _vm.showHandOverModal(
                  order.referenceNumber,
                  order.serial,
                  order.merchantReference,
                )}}},[_c('CheckSVG',{staticClass:"icon"})],1):_vm._e(),(_vm.canAnnul(order))?_c('Button',{attrs:{"variant":"ghost","width":"auto","title":_vm.$t('ANNUL')},on:{"click":function($event){return _vm.showAnnulModal(order)}}},[_c('DeleteSVG',{staticClass:"icon"})],1):_vm._e()],1)]),(_vm.displayOrderDetails[order.referenceNumber])?_c('tr',[_c('td',{staticClass:"no-padding",attrs:{"colspan":"8"}},[_c('BackOfficeBundleOrderDetails',{attrs:{"orderDetails":_vm.bundleOrderDetails[order.referenceNumber]}})],1)]):_vm._e()])})],2)]),_c('ApplicationHandOverModal',{ref:_vm.handOverModalRef,on:{"handedOver":_vm.handleHandedOver}}),_c('UpdateSerialAndMerchantReferenceModal',{ref:_vm.updateSerialNumberAndMerchantReferenceModalRef,on:{"update-serial-and-merchant-reference":_vm.updateSerialAndMerchantReference}}),_c('ApplicationAnnulModal',{ref:_vm.annulModalRef})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }